<div class='page-container lookback' *ngIf='currentLookback'>

  <div class='button-row' *ngIf='isSuccess() && !loadingPage'>
    <p-button class='primary-button lookback-again-button' (click)='showLookbackAgainDialog()'>
      Lookback again
    </p-button>

    <p-button class='primary-button sort-button'
              (click)='toggleSortDirection()'
              [pTooltip]="sortDirection === 'desc' ? 'New to old' : 'Old to new'"
              tooltipPosition='top'
              aria-label='Sort direction for match history (old to new or new to old)'>
      <i [class]="sortDirection === 'desc' ? 'bx bx-sort-up' : 'bx bx-sort-down'"></i>
    </p-button>
  </div>

  <p class='description'>
    Lookback request for common matches in <strong>{{ currentLookback.platformDisplayName }}</strong>
    between <span [innerHTML]='this.getSummonerNames()'></span> for the
    <strong>{{ currentLookback.queueTypeDisplayNames.toLowerCase() }}</strong>
    queue {{ currentLookback.queueTypeDisplayNames.includes(",") ? "types" : "type" }}.
  </p>

  <div class='status' *ngIf="currentLookback.currentStatus !== 'SUCCESS'">
    <app-spinner *ngIf='!isSuccess() && !isFailed()' />

    <p>
      <strong>{{ currentLookback.currentStatus }}</strong>
    </p>

    <p class='status-message' [innerHTML]='currentLookback.currentMessage'></p>

    @if (currentLookback.currentStatus === 'FAILED') {
      <p-button class='primary-button lookback-again-button' styleClass="mt-3" (click)='showLookbackAgainDialog()'>
        Try again
      </p-button>
    }
  </div>

  <div class='status'
       *ngIf="matches && matches.length === 0 && currentLookback.currentStatus === 'SUCCESS' && !loadingPage">
    <span class='secondary-highlight'>No matches</span> were found between these two summoners.

    <p style="margin-top: 25px;">
      Do you believe this to be incorrect?

      Please join <a href='https://discord.endoy.dev'>my Discord server</a> and give me some information about the games
      (e.g. summoner names, region, queue type, etc.).
    </p>
    <p class='note'>
      * Due to Riot API limitations, we can only look back up until 1000 games or 2 years back. <br />
      However, once a summoner has been looked up once, we will attempt to regularly fetch their match history ids.
    </p>
  </div>

  <app-simple-dialog #dialog [(visible)]='lookbackAgainDialogVisible'
                     [modal]='true'>
    <app-lookback-request-form *ngIf='lookbackRequest && lookbackAgainDialogVisible'
                               [lookbackRequest]='lookbackRequest'
                               [openInNewTab]='true'
                               (lookbackRequestEvent)='onLookbackRequest()' />
  </app-simple-dialog>

  <div class='matches' *ngIf='matches && matches.length > 0'>

    @for (match of this.matches; track match; let index = $index) {
      <app-lookback-match [currentLookback]='currentLookback'
                          [match]='match'
                          [attr.data-match-id]='match.matchId'
                          (openLookbackAgainPanelQuestionForSummoner)='this.openLookbackAgainPanelQuestionForSummoner($event)' />
    }


  </div>

  <app-spinner *ngIf='loadingPage' class='loading-page' />

  <p-overlayPanel #lookbackAgainOverlayPanel>
    <p>
      Which summoner do you want to use
      {{ lookbackAgainQuestionSummonerName + (lookbackAgainQuestionSummonerTag ? '#' + lookbackAgainQuestionSummonerTag : '') }}
      for?
    </p>

    <ul style='margin-top: 15px'>
      @for (item of [].constructor(5); track item; let index = $index) {
        @if (this.currentLookback.summoners[index]) {
          <li>
            Summoner {{ index + 1 }}: {{ this.currentLookback.summoners[index].name + (this.currentLookback.summoners[index].tag ? '#' + this.currentLookback.summoners[index].tag : '') }}
          </li>
        } @else {
          <li>Summoner {{ index + 1 }}: n/a</li>
        }
      }
    </ul>

    @for (item of [].constructor(5); track item; let index = $index) {
      <p-button [label]="'Summoner ' + (index + 1)" styleClass='primary-button'
                [style]="{'margin-top': '20px', 'margin-right': '10px'}"
                (onClick)='this.showLookbackAgainDialog(index)' />
    }
  </p-overlayPanel>
</div>
