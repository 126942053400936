@if (this.darkModeService.isDarkMode()) {
  <img (click)='this.darkModeService.setDarkMode(false)'
       class='moon'
       ngSrc='/assets/media/moon.svg'
       width='50'
       height='50'
       alt='Dark mode switch' />
} @else {
  <img (click)='this.darkModeService.setDarkMode(true)'
       class='sun'
       ngSrc='/assets/media/sun.svg'
       width='50'
       height='50'
       alt='Light mode switch' />
}
