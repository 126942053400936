import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LookbackService } from 'src/services/lookback.service';
import { QueueTypeDto } from 'src/dto/QueueTypeDto';
import { RegionDto } from 'src/dto/RegionDto';
import { LookbackRequestDto } from 'src/dto/LookbackRequestDto';
import { QueueTypeService } from 'src/services/queue-type.service';
import { RegionService } from 'src/services/region.service';
import { InitiateLookbackResponseDto } from 'src/dto/InitiateLookbackResponseDto';
import { fireToast } from 'src/helpers/alert.helpers';
import { LookbackRequestSummonerDto } from 'src/dto/LookbackRequestSummonerDto';

const LOOKBACK_LAST_SELECTED_REGION = 'LOOKBACK_LAST_SELECTED_REGION';

@Component({
  selector: 'app-lookback-request-form',
  templateUrl: 'lookback-request-form.component.html',
  styleUrls: ['lookback-request-form.component.scss'],
  providers: [QueueTypeService, RegionService, LookbackService],
})
export class LookbackRequestFormComponent implements OnInit {
  @Input({ required: true })
  public lookbackRequest: LookbackRequestDto;
  @Input()
  public openInNewTab = false;
  @Output()
  public lookbackRequestEvent = new EventEmitter<void>();

  protected availableQueueTypes: QueueTypeDto[];
  protected availableRegions: RegionDto[];

  constructor(
    private titleService: Title,
    private queueTypeService: QueueTypeService,
    private regionService: RegionService,
    private lookbackService: LookbackService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Lookback');

    this.regionService.getRegions().subscribe((regions: RegionDto[]) => {
      this.availableRegions = regions;

      if (!this.lookbackRequest.platform) {
        const lastSelectedRegion = localStorage.getItem(LOOKBACK_LAST_SELECTED_REGION);

        if (lastSelectedRegion) {
          const lastSelectedAvailableRegion = this.availableRegions.find(
            (region: RegionDto) => region.id === lastSelectedRegion,
          );

          this.lookbackRequest.platform = lastSelectedAvailableRegion
            ? lastSelectedAvailableRegion.id
            : this.availableRegions[0].id;
        } else {
          this.lookbackRequest.platform = this.availableRegions[0].id;
        }
      }
    });

    this.queueTypeService.getQueueTypes().subscribe((queueTypes: QueueTypeDto[]) => {
      this.availableQueueTypes = queueTypes;

      if (!this.lookbackRequest.queueTypes) {
        this.lookbackRequest.queueTypes = this.availableQueueTypes.map((queueType: QueueTypeDto) => queueType.id);
      }
    });
  }

  protected submitLookbackRequest(): void {
    this.lookbackService.initiateLookback(this.lookbackRequest).subscribe({
      next: (lookbackJobId: InitiateLookbackResponseDto) => {
        this.lookbackRequestEvent.emit();

        if (this.openInNewTab) {
          window.open('/lookbacks/' + lookbackJobId.lookbackId, '_blank');
        } else {
          fireToast('success', 'Lookback request submitted.');

          this.router.navigate(['/lookbacks', lookbackJobId.lookbackId]);
        }
      },
      error: (error: any) => {
        if (error.status === 400) {
          fireToast('error', error.error);
        } else if (error.status === 429) {
          fireToast('error', 'Too many requests. Please try again later.');
        }
      },
    });
  }

  protected addSummoner(): void {
    this.lookbackRequest.summoners.push({ name: '', tag: '' });
  }

  protected removeSummoner(idx: number): void {
    this.lookbackRequest.summoners.splice(idx, 1);
  }

  protected handleInputChangeForSummoner(summoner: LookbackRequestSummonerDto): void {
    if (summoner.name.includes('#')) {
      const [name, tag] = summoner.name.split('#');

      summoner.name = name;
      summoner.tag = tag;

      fireToast('info', 'Summoner name and tag have been separated. Please verify the tag is correct.');
    }

    if (summoner.name.startsWith(' ') || summoner.name.endsWith(' ')) {
      summoner.name = summoner.name.trim();
    }
  }

  protected handleInputChangeForTag(summoner: LookbackRequestSummonerDto): void {
    if (summoner.tag) {
      if (summoner.tag.startsWith(' ') || summoner.tag.endsWith(' ')) {
        summoner.tag = summoner.tag.trim();
      }

      if (summoner.tag.startsWith('#')) {
        summoner.tag = summoner.tag.substring(1);
      }
    }
  }

  protected onPlatformChange(platformId: string): void {
    localStorage.setItem(LOOKBACK_LAST_SELECTED_REGION, platformId);
    this.lookbackRequest.platform = platformId;
  }
}
